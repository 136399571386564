import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Image from '../Image';



const styles = theme =>
  createStyles({
    root: {},
    image: {
      height: '506px',
      overflow: 'hidden',
      [theme.breakpoints.up(767)]: {
        height: '660px',
      }, 
    },

  });

const InnerImage = ({classes,image}) => (

<Grid container direction="column" className={classes.root}>
  <Grid item className={classes.image}>
    <Image relativePath={image.src} className={image.className}></Image>
  </Grid>
</Grid>
    
  );

export default withStyles(styles, { withTheme: true })(InnerImage);