import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle'
import IntroBlock from '../components/IntroBlock';
import CenterContent from '../components/CenterContent';




const styles = theme =>
  createStyles({

    introWrap: {
      maxWidth: 850,
      margin: '0 auto',
      marginTop: 45,
    },

  });

const ServiceIntroSection = ({
  classes,
  title,
  subTitle,
  text

}) => (

  <CenterContent>
    <Grid
      container
      direction="column"
      className={classes.introWrap}   
    >
      <SectionTitle
        title={title}
        subTitle={subTitle}
      ></SectionTitle>

      <IntroBlock
        text={text}
      ></IntroBlock>

    </Grid>
  </CenterContent>

  );


export default withStyles(styles, { withTheme: true })(ServiceIntroSection);