import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';


const styles = theme =>
  createStyles({

    processTitle: {
      marginBottom: 17,
      fontWeight: 600,
      fontSize: 22,
      letterSpacing: '0.5px',
    },
    textWrap: {
      marginTop: 7,
      marginBottom: 4,
      [theme.breakpoints.up(767)]: {
        marginTop: 0
      },
    },
    processDesc: {
      marginBottom: 5,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      [theme.breakpoints.up(960)]: {
        marginBottom: 10
      },
    },


  });

const ProcessText = ({classes,processTitle,processDesc}) => (
  <Grid
    container
    direction="column"
    className={classes.textWrap}
  >
    <Typography
      variant="h6"
      color="primary"
      className={classes.processTitle}
    > {processTitle} </Typography>

    <Typography
      variant="body1"
      color="primary"
      className={classes.processDesc}
    > {processDesc}  </Typography>
      
  </Grid>

  );

  export default withStyles(styles, { withTheme: true })(ProcessText);