import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const styles = theme =>
  createStyles({
    root: {
      padding: '62px 40px 50px',
      backgroundColor: '#1BB25F',
    },
    invertedRoot: {
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      paddingBottom: 30,
    },

  });

const CtaBlock = ({
  classes,
  title,
  inverted = false,
  children,
  textColor = "secondary",
  ...rest
}) => (
  <Grid
    container
    direction="column"
    className={classNames(classes.root, {
      [classes.invertedRoot]: inverted
    })}
    {...rest}
  >
    <Grid item className={classes.title}>
      <Typography variant="h2" color={textColor} className={ classes.titleSize }>
        {title}
      </Typography>
    </Grid>
    <Grid item container direction="column" alignItems="center">
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(CtaBlock);
