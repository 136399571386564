import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';


const styles = theme =>
  createStyles({

    blockWrap: {
      backgroundColor: theme.palette.grey.main,
      padding: '50px',
      marginTop: 30,
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up(767)]: {
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: '40px 55px',
      },

      [theme.breakpoints.up(960)]: {
        margin: '30px 0',
      },
    },

    blockText: {
      fontSize: 30,
      lineHeight: '34px',   
      fontWeight: 600,
      [theme.breakpoints.up(767)]: {
        fontSize: 38,
        lineHeight: '42px',
        letterSpacing: '1px',
      },
    },

  });


const IntroBlock = ({classes,text}) => (

  <Grid
    container
    className={classes.blockWrap}
    direction="column"
  >
    <Typography
      variant="body2"
      color= "primary"
      className={classes.blockText}
      >
        {text}
    </Typography>

  </Grid>
  
)

export default withStyles(styles, { withTheme: true })(IntroBlock);