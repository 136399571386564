import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';


const styles = theme =>
  createStyles({

    sectionTitle: {
      fontSize: 40,
      textAlign: 'center',
      fontWeight: '600',
      letterSpacing: 1,
      padding: '0 15px 20px 15px',
      [theme.breakpoints.up(400)]: {
        fontSize: 43,
        
      },
      [theme.breakpoints.up(767)]: {
        fontSize: 54,
        letterSpacing: 0,
      },
    },
    subTitle: {
      fontWeight: '200',
      fontSize: 30,
      marginTop: 20,
      [theme.breakpoints.up(767)]: {
        fontSize: 54,
        marginTop: 0,
      },
    }

  });

const SectionTitle = ({classes,title,subTitle}) => (

  <Typography
    variant="h2"
    color="primary"
    className= {classes.sectionTitle}
  >
    {title} <br/> 
    <div className={classes.subTitle}> {subTitle} </div>  
  </Typography>

  );

  export default withStyles(styles, { withTheme: true })(SectionTitle);