import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Images
import { ProjectConsulationServiceImages } from '../imageURLs';

//Data
import keyProcesses from '../../data/project-consultation/key-processes.json';

import CenterContentNarrow from '../components/CenterContentNarrow';
import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';
import CtaBlock from '../components/CtaBlock';
import CTAButton from '../components/CTAButton/CTAButton';
import InnerImage from '../components/InnerImage/innerImage';
import ProcessText from '../components/singleproject/processText';
import ServiceIntroSection from '../components/ServiceIntroSection';

const styles = theme =>
  createStyles({

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    twoColumnsWrap: {
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      paddingTop: 0,
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingTop: 42,
        paddingBottom: 60,
      },
    },

    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 52%',
        maxWidth: '52%',
        paddingRight: 110,
        paddingTop: 0,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '0 110px 40px 0'
      },
    },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 48%',
        maxWidth: '48%',
      },
    },

    rightColumnText: {
      backgroundColor: theme.palette.grey.main,
      padding: '40px',
      alignItems: 'flex-start',
    },

    rightColumnImage: {
      paddingTop:  15,
      maxHeight: '506px',
      overflow: 'hidden',
      [theme.breakpoints.up(767)]: {
        maxHeight: '580px',
      },
      [theme.breakpoints.up(960)]: {
          paddingTop: 0
        },
    },

    listItemsWrap: {
      paddingLeft: 20,
      marginTop: 0,
    },
  });

const ProjectConsultationServicePage = ({classes,toggleQuoteComp,children}) => (

<React.Fragment>

  <SEO
    title="Project Consultation - Twin View Turf - Producer of Australia’s Best Lawns"
    keywords={[
      `Turf care guides`,
      `care guides`,
      `Turf`,
      `Lawns`,
      `Best Lawns`,
      `Australia’s Best Lawns`,
      `lawn producer`,
      ]}
  />

    {/* HERO SECTION START */}
    <InnerImage 
      image={{className: classes.img, src: ProjectConsulationServiceImages.hero}}
    ></InnerImage>
    {/* HERO SECTION END */}

    {/* SERVICES - INTRO SECTION */}
    <ServiceIntroSection
      title="Project Consultation"
      subTitle="360-degree project management."
      text="We bring 35 years of knowledge and industry experience to successfully manage your project from start to finish."
    ></ServiceIntroSection>


    {/* TWO COLUMNS SECTION START */}
    <CenterContentNarrow>
      <Grid
        container
        direction="column"
        className={classes.twoColumnsWrap}
      >
        {/* left column */}
        <Grid
          container
          direction="column"
          className={classes.leftColumnWrap}
        >

        {keyProcesses.map(process => (
          <ProcessText
            key={process.title}
            processTitle={process.title}
            processDesc={process.desc}
          />
        ))}

        </Grid>
        {/* right column */}
        <Grid
          container
          direction="column"
          className={classes.rightColumnWrap}
          >
          {/* right column image */}
          <Grid
            container
            className={classes.rightColumnImage}
          >
            <InnerImage
              image={{ className: classes.img, src: ProjectConsulationServiceImages.thumb }} 
            ></InnerImage>
          </Grid>

        </Grid>
      </Grid>
    </CenterContentNarrow>

    {/* CTA SECTION START */}
    <CtaBlock title="Planning a field construction?">
      <CTAButton
        inverted
        ctaHref="/contact"
        ctaText="Get a quote"
      />
    </CtaBlock>
    {/* CTA SECTION END */}

  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(ProjectConsultationServicePage));